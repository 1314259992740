import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import { useDialogContext, type DialogProps } from "@/contexts/dialogContext";
import { api } from "@/lib/api";
import { cn } from "@/utils/cn";
import { formatDuration } from "@/utils/formatDuration";
import { useQuery } from "@tanstack/react-query";
import type React from "react";
import { Progress } from "../ui/progress";
import { toast } from "sonner";

export const OrderDetailsDialog: React.FC<DialogProps<"orderDetails">> = ({
	isOpen,
	onClose,
	payload: { id },
}) => {
	const {
		data: order,
		isLoading,
		error,
	} = useQuery({
		queryKey: ["order", id],
		queryFn: async () => {
			const res = await api.orders({ id }).get();
			return res.data;
		},
		enabled: isOpen,
	});

	const { openDialog } = useDialogContext();

	if (isLoading) {
		return <div />;
	}

	if (error || !order) {
		toast.error("Error loading order details");
		return <div>Error loading order details</div>;
	}

	const details = [
		{ label: "Order ID", value: order.id },
		{ label: "Target", value: order.targetAddress, className: "truncate" },
		{ label: "Resource", value: order.resourceType },
		{ label: "Amount", value: order.resourceAmount.toLocaleString() },
		{ label: "Price", value: `${order.price} sun` },
		{ label: "Duration", value: `${formatDuration(order.duration)}` },
		{
			label: "Fulfilled",
			value: (
				<div className="h-2 w-full rounded-full bg-secondary">
					<Progress
						value={
							order.freeze.toString() !== "0"
								? Math.min(100, (Number(order.frozen) / Number(order.freeze)) * 100)
								: 0
						}
					/>
				</div>
			),
		},
		{
			label: "Status",
			value: (
				<span
					className={
						order.status === "PENDING" ? "text-yellow-500" : "text-green-500"
					}
				>
					{order.status}
				</span>
			),
		},
		{ label: "Created", value: new Date(order.createdAt).toLocaleString() },
		{ label: "Updated", value: new Date(order.updatedAt).toLocaleString() },
	];

	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogContent className="sm:max-w-[425px]">
				<DialogHeader>
					<DialogTitle className="text-center font-bold text-2xl">
						Order Details
					</DialogTitle>
				</DialogHeader>
				<DialogDescription>
					<div className="space-y-4 py-4">
						{details.map((detail) => (
							<OrderDetailRow key={detail.label} {...detail} />
						))}
					</div>
				</DialogDescription>
				{/* <DialogFooter>
					<Button
						onClick={() => openDialog("transactions", { id })}
						className="w-full"
					>
						Show Transactions
					</Button>
				</DialogFooter> */}
			</DialogContent>
		</Dialog>
	);
};

interface OrderDetailRowProps {
	label: string;
	value: React.ReactNode;
	className?: string;
}

const OrderDetailRow: React.FC<OrderDetailRowProps> = ({
	label,
	value,
	className,
}) => (
	<div className={cn("grid grid-cols-4 items-center gap-4", className)}>
		<span className="font-semibold text-muted-foreground">{label}:</span>
		<span className="col-span-3">{value}</span>
	</div>
);
